<template>
  <div style="margin-top: 30px">
    <el-row>
      <el-form ref="elForm" inline :model="formData" :rules="rules" label-width="120px">
        <el-row>
          <el-col>

            <el-form-item label="转组红人">
              <sign-artist-search-custom
                  :type="``"
                  @handleSelect="selectArtist"
                  :show-first-group="false"
                  style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>

            <el-form-item label="转入部门-组" prop="group_id">
              <!--          <DeptCascader ref="refDeptCascader" @change="selectDept"/>-->
              <company-select @handleSelect="selectDept"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm" v-if="userPermissions.indexOf('sign_transfer_group') > -1">
                确认转组
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </el-row>
    <el-row>
      <el-col :span="20" :offset="2">
        <ArtistGroupsList ref="refArtistGroupsList" :kolId="formData.kolId"/>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import ArtistGroupsList from '@/pages/sign_info/components/ArtistGroupsList'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import SignArtistSearchCustom from '@/components/artist/SignArtistSearchCustom'

export default {
  name: 'TransferGroup',
  components: { ArtistGroupsList, ArtistSearchCustom, SignArtistSearchCustom },
  props: [],
  data() {
    return {
      formData: {
        kolId: null,
        nickname: null,
        group_id: null,
        sign_id: null,
        id: null
      },
      rules: {
        nickname: [{
          required: true,
          message: '请检索并选择转组红人',
          trigger: 'change'
        }],
        group_id: [{
          required: true,
          message: '请选择转入部门-组',
          trigger: 'change'
        }],
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
        this.$confirm('此操作将[确认转组]操作, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.commitSave()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });


      })
    },
    async commitSave() {
      let subData = {
        nickname: this.formData.nickname,
        group_id: this.formData.group_id,
        id: this.formData.kolId
      }
      let info = await this.$api.signInfoGroupTransfer(subData)
      if (info === 'ok') {
        this.$notify.success('转组成功')
        if (this.$refs['refArtistGroupsList'])
          this.$refs['refArtistGroupsList'].initList()
      }
    },

    // 搜索用户,qs请求参数，cb回调函数，详见组件用法
    async searchUser(qs, cb) {
      let list = await this.$api.searchSignInfo(qs)
      cb(list)
    },
    // 处理搜索结果的选中
    /*    handleSelectQuery(item) {
          console.log(item)
          this.formData.id = item.id
          this.formData.group_id = item.group_id
          this.formData.nickname = item.nickname

          if (this.formData.id) {
            // this.$refs['elForm'].validateField('id')
            this.$refs['refArtistGroupsList'].initList()

          }
        },*/
    clearSelected() {
      this.formData.id = null
    },
    async selectDept(val) {
      // console.log('val',val)
      let group_id = null
      if (val && val.length !== 0) {
        group_id = val[0]
      }

      this.formData.group_id = group_id
    },
    selectArtist(selectInfo) {
      if (selectInfo) {
        this.formData.sign_id = selectInfo.sign_id
        this.formData.kolId = selectInfo.kolId
        this.formData.nickname = selectInfo.nickname
      }

      // this.formData.group_id = selectInfo.group_id
    },
  }
}

</script>
<style scoped>
</style>
