<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>转组记录</span>
        <el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-refresh" @click="initList">刷新
        </el-button>
      </div>
      <!--   table列表     -->
      <div class="default-table">
        <ApeTable ref="apeTable"
                  :data="dataList"
                  :columns="columns"
                  :loading="loadingStatus"
                  :pagingData="pagingData"
                  @switchPaging="switchPaging"
                  highlight-current-row
                  border>
        </ApeTable>
      </div>
    </el-card>
  </div>
</template>

<script>
import ApeTable from '@/components/ApeTable'

export default {
  name: "ArtistGroupsList",
  components: {
    ApeTable,
  },
  props: {
    kolId: {
      type: [Number, String],
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      loadingStatus: false,
      dataList: [],
      orderSort: { time_day: 'desc', id: 'desc' },//默认排序规则
      columns: [
        {
          title: '红人昵称',
          value: 'nickname',
          width: 120,
          header_align: 'center',
          align: 'center',
        },
        {
          title: '转入分组名',
          value: 'group_name',
          width: 120,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        // {
        //   title: '年份',
        //   value: 'year',
        //   width: 80,
        //   header_align: 'center',
        //   align: 'center'
        // },
        // {
        //   title: '月份',
        //   value: 'month',
        //   width: 80,
        //   header_align: 'center',
        //   align: 'center'
        // },
        {
          title: '日期',
          value: 'time_day',
          width: 100,
          header_align: 'center',
          align: 'center'
        },
        {
          title: '备注',
          value: 'remark',
          width: 200,
          header_align: 'center',
          align: 'left'
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 160,
          header_align: 'center',
          align: 'left'
        },
      ],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15,
        offset: 0
      },
    }
  },
  watch: {
    kolId(val, oldVal) {
      // console.log('val', val)
      if (val !== oldVal)
        this.initList()
    }
  },
  methods: {
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.initList()
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    async initList(type) {
      this.loadingStatus = true
      this.dataList = []
      if (this.kolId) {
        let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
        let searchCondition = { id: this.kolId }
        // Object.assign(searchCondition, pagingInfo)
        Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
        let { list, pages } = await this.$api.signArtistGroupList(searchCondition)
        this.dataList = list
        this.pagingData.total = pages.total
        this.pagingData.offset = pages.offset
      }

      this.loadingStatus = false
    }
  }
}
</script>

<style scoped>

</style>
