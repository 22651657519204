<template>
  <div class="artist_search_custom">
    <artist-search @handleSelect="chooseArtist" style="width: 200px;display: inline;margin-right: 10px"
                   :clear="clear" v-if="showSearchTool"/>

    <el-cascader
        v-model="value"
        collapse-tags
        :options='options'
        :props=props
        @change='selectDept' style='width: 300px; margin-right: 10px' clearable>
    </el-cascader>

    <div style="display: inline">
      <el-switch v-model="searchCondition.sign_status" active-text="在约" active-value="Y" inactive-text="解约"
                 inactive-value="N" @change="getGroupArtists"></el-switch>
      <template v-if="artists.length>0" style="margin-left: 10px">
        <el-radio-group v-model="searchCondition.kolId"
                        @change="changeRadio"
                        style="margin-left: 10px"
                        size="mini">
          <el-radio-button :label="artist.id" v-for="(artist,idx) in artists" :key="idx">{{
              artist.nickname
            }}
          </el-radio-button>
        </el-radio-group>
      </template>
      <span style="margin-left: 10px" v-else>暂无</span>
<!--      &nbsp;-->
<!--      <el-tag type="warning" size="medium" effect="plain" v-if="artists?.length>0"> {{ artists?.length }} 个红人-->
<!--      </el-tag>-->
    </div>
  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import _ from 'lodash'

export default {
  name: "SignArtistSearchCustom",
  components: {
    ArtistSearch,
  },
  props: {
    showFirstGroup: {
      type: Boolean,
      default() {
        return false
      }
    },
    type: {
      type: String,
      default: ""//revenue_data revenue_enter trend_data trend_enter
    },
    showSearchTool: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      searchCondition: {
        sign_id: null,
        kolId: null,
        nickname: null,
        group_id: null,
        group_name: null,
        sign_status: 'Y'
      },
      artistPlatforms: {},
      artists: [],
      options: [],
      data: [],
      props: { expandTrigger: 'hover', value: 'dpt_id', label: 'name', multiple: false, checkStrictly: true }
      , value: ''
      , clear: false
    }
  },
  methods: {
    async chooseArtist(item) {
      this.value = item?.group_id
      if (item) {
        this.searchCondition.group_id = item.group_id
        this.searchCondition.group_name = item.group_name
        // await this.showSearchInfo()
        this.searchCondition.artist_id = item.id
        this.searchCondition.nickname = item.nickname
      } else {
        this.searchCondition.artist_id = null
        this.searchCondition.nickname = null
        this.searchCondition.group_id = null
        this.searchCondition.group_name = null
        this.artists=[]
      }
      await this.getGroupArtists('search')
    },
    async selectDept(selectArr) {
      if(selectArr){
        this.clear=true
        let deptArr = []
        deptArr.push(selectArr[selectArr.length - 1])
        if (deptArr && deptArr.length !== 0) {
          this.searchCondition.group_id = deptArr[0]
        } else {
          this.searchCondition.group_id = null
          this.searchCondition.group_name = null
          this.artists = []
        }
        await this.getGroupArtists()
        this.$emit('handleSelect', this.searchCondition)
      }

    },
    async changeRadio(val) {
      this.searchCondition.kolId = val
      let artist = this.artists.find((value) => value.id === val)
      this.searchCondition.nickname = artist ? artist.nickname : ''
      this.handleSelect()
    },
    handleSelect() {
      this.$emit('handleSelect', this.searchCondition)
    },
    // async currentGroup(group) {
    //   if (group && group.dpt_id) {
    //     await this.setGroupInfo(group.dpt_id, group.name)
    //   }
    // },
    // async setGroupInfo(group_id, group_name) {
    //   this.searchCondition.group_id = group_id
    //   this.searchCondition.group_name = group_name
    //   this.artists = []
    //   await this.getGroupArtists()
    // },
    async getGroupList() {
      let { list } = await this.$api.getSignGroups()
      this.data = list
      this.calcOptions()
    },
    async getGroupArtists(type) {
      // this.clear = true
      // this.searchCondition.artist_id = null
      // this.searchCondition.nickname = null
      let groupId = this.searchCondition.group_id
      let sign_status = this.searchCondition.sign_status
      if (groupId) {
        let data = await this.$api.getGroupKols(groupId, sign_status)
        let nickname = this.searchCondition.nickname
        // this.clear = false
        if(type==='search'){
          let incloud = _.findIndex(data, function(o) {
            return o.nickname == nickname
          })
          if (incloud === -1) {
            //不在当前列表中，切换在约解约状态
            sign_status = sign_status === 'Y' ? 'N' : 'Y'
            this.searchCondition.sign_status=sign_status
            data = await this.$api.getGroupKols(groupId, sign_status)
          }
        }

        let list = data
        this.artists = list
        if (type !== 'search' && list && list?.length > 0) {
          this.searchCondition.nickname = list[0].nickname
          this.searchCondition.kolId = list[0].id

        } else {

          let name = this.searchCondition.nickname
          let ofIndex = _.findIndex(list, function(o) {
            return o.nickname == name
          })
          if (ofIndex > -1) {
            this.searchCondition.kolId = list[ofIndex].id
          }
        }
        this.$nextTick(() => {
          this.handleSelect()
        })
      }
    },
    async showSearchInfo() {
      await this.getGroupArtists('search')
    },
    calcOptions() {
       this.options = this.handleTree(this.data, 'dpt_id', 'parent_id', 'children', '1')
    }
  },
  created() {
    this.getGroupList()
  }
}
</script>

<style scoped>
/*.artist_search_custom {*/
/*  border: red 1px dashed;*/
/*  width: 100%;*/
/*}*/

</style>
